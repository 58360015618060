<template>
	<cms-editor-text :value="`${prefix}.content`" :props="{variant: 'paragraph', tag: 'div'}"/>
</template>

<script>
export default {
	props: {
		prefix: String,
		value: Object
	}
}
</script>
